import React from 'react'
import { useState } from 'react'

export default function TextForm(props) {
    const [text, setText] = useState("Write Something Here");
    const capitalizeText= () => {
        setText(text.toUpperCase());
    }
    const smallText= () => {
        setText(text.toLowerCase());
    }
    const changeTextAreaValue= (event) => {
        setText(event.target.value);
    }
    const handleCopy= () => {
       let text = document.getElementById('myText');
       text.select();
       navigator.clipboard.writeText(text.value);
    }
    const handleExtraSpaces = () => {
        let newText = text.split(/[ ]+/);
        setText(newText.join(' '));
    }
    return (
    <div>
        <div className={`container my-3 text-${props.reverseMode}`}>
            <h1>{props.heading}</h1>
            <div className="form-group">
                <textarea style={{backgroundColor:(props.mode==='light')?'white':'#26355d'}} value={text} id='myText' rows="10" className={`form-control text-${props.reverseMode}`} onChange={changeTextAreaValue}></textarea>
            </div>
            <button className="btn btn-sm btn-success my-2 mx-1" onClick={capitalizeText}>Capitalize The Text</button>
            <button className="btn btn-sm btn-success my-2 mx-1" onClick={smallText}>Small The Text</button>
            <button className="btn btn-sm btn-success my-2 mx-1" onClick={handleCopy}>Copy Text</button>
            <button className="btn btn-sm btn-success my-2 mx-1" onClick={handleExtraSpaces}>Remove Extra Space</button>
        </div>
        <div className={`container my-2 text-${props.reverseMode}`}>
            <span>Total Character: {text.length}</span> <span>Total Word: {(text.length>0)?text.split(' ').length:0}</span><br/>
            <span>Need {(text.length>0)?text.split(' ').length*0.008:0} minute(s) to read.</span>
        </div>
        <div className={`container my-3 text-${props.reverseMode}`}>
            <h2 className="my-1">Preview</h2>
            <p>{text}</p>
        </div>
    </div>
  )
}
