import Navbar from './components/Navbar';
import TextForm from './components/TextForm';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import About from './components/About';

function App() {
  const [mode,setMode]=useState('light');
  const [reverseMode,setReverseMode]=useState('dark');

  const toggleMode =() => {
    if(mode==='light'){
      setMode('dark');
      setReverseMode('light');
      document.body.style.backgroundColor='#000057';
    }
    else{
      setMode('light');
      setReverseMode('dark');
      document.body.style.backgroundColor='white';
    }
  }

  return (
    <>
    <Router>
      <Navbar title="Pigeon" mode={mode} reverseMode={reverseMode} toggleMode={toggleMode}  />
      <div className="container my-3">
        <Routes>
          <Route exact path="/" element={<TextForm heading="Write Some Text Here"  mode={mode}  reverseMode={reverseMode} />} />
          <Route exact path="/about" element={<About mode={mode}  reverseMode={reverseMode} />}/>
        </Routes>
      </div>
    </Router>
   </>
   
  );
}

export default App;
