import React from 'react'

export default function About(props) {
  return (
    <div className={`my-3 text-${props.reverseMode}`}>
      <h1>This is About Page.</h1>
    </div>
  )
}

